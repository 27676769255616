import axios from 'axios';
import config from '../components/Helper/config';

const axiosInstance = axios.create({
	baseURL: config.base_api,
	headers: { Authorization: config.token, 'Content-Type': 'application/json' },
});
class UserApi {
	baseURL = config.base_api;
	token = config.token;

	getAllUser(params) {
		return axios.get(this.baseURL + `users/`, {
			params,
			headers: {
				Authorization: this.token,
				'Content-Type': 'application/json',
			},
		});
	}

	getUserInformation(id, params) {
		const method = 'get';

		let url = this.baseURL + `users/users/`;
		if (id) {
			url = this.baseURL + `users/users/${id}`;
		}
		return axiosInstance({ method, url, params });
	}

	updateUserInformation(id, data) {
		let url;
		url = this.baseURL + `users/users/${id}/`;

		let bodyFormData = new FormData();

		if (data.name && data.name.trim().length) {
			bodyFormData.append('name', data.name);
		}
		if (data.password) {
			bodyFormData.append('password', data.password);
		}
		if (data.email) {
			bodyFormData.append('email', data.email);
		}

		return axios.patch(url, bodyFormData, {
			params: {},
			headers: {
				Authorization: this.token,
				'Content-Type': 'application/json',
			},
		});
	}
	inviteProjectParticipantUser(id, data) {
        const method = 'post'
        const url = this.baseURL + `projects/${id}/invite_participant/`
        return axiosInstance({ method, url, data })
    }
}

export default UserApi;
